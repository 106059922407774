@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.pageTitle {
  text-align: center;
}

@media (max-width: 767px) {
  .sideNav {
    display: none;
  }
}

.staticPageWrapper {
  width: 100%;
  margin: 0px 0px 0px 158px;
  padding: 30px 24px 30px 24px;
  background-color: white;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    width: calc(100% - 158px);
    margin: 0px 0px 0px 158px;
    padding: 120px 24px 30px;
  }

  @media screen and (max-width: 767px) {
    margin: 0px auto !important;
    width: 100% !important;
  }

  @media (min-width:1500px) {
    padding: 120px 34px 30px;
  }
}

.noWrapper {
  width: 100%;
  margin: 0px 0px 0px 158px;
  padding: 30px 24px 30px 24px;
  background-color: white;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    width: calc(100% - 158px);
    margin: 0px 0px 0px 158px;
    padding: 155px 24px 30px;
  }

  @media screen and (max-width: 767px) {
    margin: 0px auto !important;
    width: 100% !important;
  }

  @media (min-width:1500px) {
    padding: 155px 34px 30px;
  }
}

@media (max-width: 767px) {
  .sideNav {
    display: none;
  }
}


.mobile {
  background-image: linear-gradient(to right, #080808, #14213d);
  height: 86px;
  /* margin-bottom: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  align-self: center !important;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.mobileText {
  color: white;
}

.title {
  font-size: 40px;
  margin-bottom: 4pc;
  margin-top: 2pc;

  @media (min-width: 768px) {
    padding-left: var(--sideNavWidth);
  }

  @media only screen and (max-width: 768px) {
    margin-top: 14pc;
    text-align: center;
    line-height: 60px;
  }
}

.contentWrapper {
  padding: 40px;
}

@media screen and (max-width: 768px) {
  .contentWrapper {
    margin: 10px;
  }
}

.contactButton {
  background: var(--marketplaceColorDark) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  height: 4pc;
  padding: 0 2pc;
  color: white;
}

.contactText1 {
  color: var(--marketplaceColorDark);
  margin-bottom: 0;
}

.contactText2 {
  font-size: 25px;
  font-weight: 100;
  margin-bottom: 0;
}

.contactText3 {
  color: var(--matterColor);
  font-size: 15px;
  line-height: 25px;
}

.orangeText {
  background-color: rgba(253, 165, 0, 0.15);
  border-radius: 15px;
  padding: 0 5px;
}

.boxWrapper {
  border: 1px solid #e9e9e9;
  border-radius: 15px;
  margin-bottom: 2pc;
  padding: 0 10px;
}

.image1 {
  width: 100%;
  height: 100%;
}

.text1 {
  color: var(--marketplaceColorDark);

  @media screen and (max-width: 768px) {
    margin: 0 10px;
  }
}

.text2 {
  font-size: 40px;
  font-weight: 100;
  line-height: 50px;

  @media screen and (max-width: 768px) {
    font-size: 30px;
    margin: 0 10px;
  }
}

.text3 {
  color: #919191;
  font-size: 15px;
  text-align: justify;
  margin-right: 20px;

  @media screen and (max-width: 768px) {
    margin: 10px;
  }
}

.text4 {
  text-align: justify;
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  width: 90%;

  @media (--viewportMedium) {
    max-width: 650px;
  }
}

.dottedButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #cfcfcf;
  border-radius: 15px;
  height: 4pc;
  width: 80%;
  cursor: pointer;
  z-index: 2;

  @media only screen and (max-width: 768px) {
    margin-top: 2pc;
  }
}

.topInfo {}

.topInfoItem {
  display: flex;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 15px;
  width: 100%;
  height: 100px;
  padding: 20px;
}

.itemPic {
  object-fit: cover;
  border-radius: 15px;
}

.itemRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 20px;
}

.bottomInfo {
  margin-top: 40px;
}

.reservationsWrapper {
  width: 100%;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 15px;
}

.reservationsHeading {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  border-bottom: 1px solid rgb(196, 196, 196);
}

.notificationsHeading {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  flex-direction: column;
  border-bottom: 1px solid rgb(196, 196, 196);
}

.notifactionList {
  list-style: disc;
  padding-left: 24px;
}

.itemList {
  margin-top: 0;
}

.notificationsWrapper {
  width: 100%;
  border: 1px solid rgb(196, 196, 196);
  border-radius: 15px;
  /* max-height: 110px; */
}

.reservationsItem {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid rgb(196, 196, 196);
  margin: 0 10px;
}

.notificationsItem {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px 0;
  margin: 0 10px;
  text-align: center;
}

.listingTitle {
  margin: 0;
}

.moneyEarned {
  margin: 0;
  color: var(--successColor);
}

.messageContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  &>div {
    &>p {
      font-size: 14px;
      font-weight: 600;
      margin: 0px;
    }
  }
}

.author {
  display: flex;

  &>a {
    margin-right: 16px;
  }
}